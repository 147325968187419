import React from "react"
import { graphql } from "gatsby"
import PageTitle from "components/pageTitle"
import Layout from "components/layout"
import Seo from "components/seo"
import ReactHtmlParser from "html-react-parser"

const AgreementPage = ({ data }) => {
  return (
    <Layout>
      <div className="agreement-page">
        <div className="contents">
          <PageTitle
            title="Personal Information Agreement"
            subTitle="個人情報の取扱いに関する同意事項"
          />
          {ReactHtmlParser(
            data
              .strapiPersonalInformationAgreementPersonalInformationAgreementTextnode
              ?.personal_information_agreement ?? ""
          )}
        </div>
      </div>
    </Layout>
  )
}
export const Head = () => <Seo title="Personal Information Agreement ー 個人情報の取扱いに関する同意事項" description="" />
export default AgreementPage

export const query = graphql`
  query {
    strapiPersonalInformationAgreementPersonalInformationAgreementTextnode {
      personal_information_agreement
    }
  }
`
